@import 'variables';

html {
  font-size: 12px;
  font-family: 'Josefin Sans', sans-serif;
  overflow-x: hidden;
  width: 100vw;
}

body {
  min-height: 100vh;
  width: 100%;
  background-color: #ffffff;
  margin: 0;
  color: #163648;
  height: 100%;
  top: 0 !important; // Google translate widget made me do this
}

.goog-te-banner-frame {
  display: none !important; // Google translate widget made me do this
}

a:hover {
  text-decoration: underline;
}

#root {}

.bg-dinamia-blue {
  background-color: rgba($blue, var(--tw-bg-opacity));
}

.hover\:bg-dinamia-blue {
  &:hover {
    background-color: rgba($blue, var(--tw-bg-opacity));
  }
}

.md\:bg-dinamia-blue {
  @media (min-width: 768px) {
    background-color: rgba($blue, var(--tw-bg-opacity));
  }
}

.text-dinamia-blue {
  color: $blue;
}

.bg-light-blue {
  background-color: rgba($lightBlue, var(--tw-bg-opacity));
}

.text-light-blue {
  color: $lightBlue;
}

.bg-dinamia-purple {
  background-color: rgba($purple, var(--tw-bg-opacity));
}

.text-dinamia-purple {
  color: $purple;
}

.gradient-test {
  height: 40px !important;
  width: 100px;
  border-radius: 12px;
  background: rgb(154, 74, 246);
  background: linear-gradient(to bottom right,
      rgba(154, 74, 246, 1) 50%,
      rgba(75, 173, 244, 1) 100%);
}

.fill-white {
  fill: white;
}

.goog-logo-link {
  display: flex !important;
}

.stroke-1 {
  stroke-width: 1;
}
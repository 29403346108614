@import '../../styles/variables.scss';
@import '../../components/effects/effects.scss';

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.news {
  .section {
    font-family: sans-serif;
  }
  .section:not(.section-collapse) {
    overflow-wrap: break-word;
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 0.5rem;
    margin-bottom: 2rem;

    &.nomb {
      margin-bottom: 0;
    }
  }
}

@media print {
  .page-break {
    page-break-after: auto !important;
    break-after: auto !important;
    page-break-inside: avoid;
    -webkit-region-break-inside: avoid;
  }

  .section-wrapper {
    page-break-after: auto !important;
    break-after: auto !important;
    page-break-inside: avoid;
    -webkit-region-break-inside: avoid;
  }

  .ReactCollapse--collapse {
    height: auto !important;
  }
}

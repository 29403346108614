@import '../../../styles/variables.scss';

#google_translate_element {
    &>div {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        gap: 1rem;
    }
}
.section-tableau {
  figure > * {
    max-width: 100%;
  }
  iframe {
    height: 350px !important;
    @screen md {
      height: 600px !important;
    }
  }
}

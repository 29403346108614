@import '../../styles/variables.scss';

#news-list {

  .pill-gradient {
    background: $purple;
    background: linear-gradient(to right, #60a5fa 25%, #b550bc 100%);
    &:hover {
      background: linear-gradient(to right, #5397eb 25%, $purple 100%);
    }
  }
}

@import '../../../styles/variables.scss';

.toc {

  .toc-title {
    color: $lightBlue;
  }

  .toc-indicator {
    position: absolute;
    z-index: 100;
    top: 0;
    left: -12px;
    height: 1.75em;
    width: 4px;
    border-radius: 12px;
    background-color: $lightBlue;
    background: linear-gradient(to bottom, $lightBlue 30%, $purple 100%);
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    &.active {
      opacity: 1;
      transition: opacity 0.1s ease-in-out;
    }
  }
}

.section.post-richtext {
  .ql-container {
    overflow-y: visible;
  }

  .quill .ql-editor {
    overflow-y: visible;
    font-size: 12px;
    font-family: Arial, sans-serif;
    ul {
      padding-left: 0;
      li {
        padding-left: 1.5em;
        list-style-type: none;
        &::before {
          content: '\2022';
          margin-left: -1.5em;
          margin-right: 0.3em;
          text-align: left;
          display: inline-block;
          white-space: nowrap;
          width: 1.2em;
        }
      }
    }

    strong {
      @apply font-bold;
    }

    p,
    li {
      @apply text-lg;
    }

    p:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

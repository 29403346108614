.nice-dates {
    width: 100%;
    height: 100%;

    .nice-dates-day_month {
        top: 4%;
        font-size: 8px;
    }

    .nice-dates-week-header_day {
        font-size: 10px;
    }

    .nice-dates-popover.-open {
        right: 2%;
        width: 96%;
        border-radius: 0.125rem;
        padding: 0.25rem;
    }

    .nice-dates-day:before {
        background-color: rgba(234, 88, 12, var(--tw-bg-opacity));
    }

    .nice-dates-day {

        font-size: 10px;

        &:after {
            border: 2px solid rgba(234, 88, 12, 1);
        }

        &:hover:after {
            background-color: #fff;
        }
    }
}
@import '../../styles/variables.scss';

#main-menu {
  background: #77387f;
  background: linear-gradient(to right, #2e4481 0%, #77387f 100%);

  svg {
    path {
      stroke-width: 1px;
    }
  }

  .mobile-menu {
    /* Position and sizing of burger button */
    .bm-burger-button {
      position: relative;
      width: 36px;
      height: 30px;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
      background: transparent;
    }

    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
      background: #ffffff;
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
      height: 48px;
      width: 48px;
    }

    /* Color/shape of close button cross */
    .bm-cross {
      height: 48px;
      width: 48px;
    }

    /*
      Sidebar wrapper styles
      Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
      */
    .bm-menu-wrap {
      position: fixed;
      height: 100%;
      top: 0;
    }

    /* General sidebar styles */
    .bm-menu {
      background: $purple;
      background: linear-gradient(to bottom right, #3c58a1 0%, $purple 100%);
      z-index: 10000;
      padding: 2.5em 3em;
      font-size: 1.15em;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
      fill: #373a47;
    }

    /* Wrapper for item list */
    .bm-item-list {
      color: #b8b7ad;
      padding: 0.8em;
      display: flex;
      flex-direction: column;
    }

    /* Individual item */
    .bm-item:not(img),
    .bm-item-list > div {
      display: inline-block;
      padding: 1rem 0;
      color: #ffffff;
    }

    /* Styling of overlay */
    .bm-overlay {
      background: rgba(0, 0, 0, 0.3);
    }
  }
}

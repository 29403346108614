@import '../../styles/variables.scss';

#glossary {
  .overlay {
    background-color: rgba(#ffffff, 0.95);
  }
  .header {
    min-height: 400px;
  }
}

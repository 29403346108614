@import '../../../styles/variables.scss';

.post-meta {
  .meta-title {
    color: $lightBlue;
  }

  .authors > div:last-child {
    border-color: $lightBlue;
  }
}

.section.post-richtext {
  ul {
    padding-left: 0;
    li {
      padding-left: 1.5em;
      list-style-type: none;
      &::before {
        content: '\2022';
        margin-left: -1.5em;
        margin-right: 0.3em;
        text-align: left;
        display: inline-block;
        white-space: nowrap;
        width: 1.2em;
      }
    }
  }

  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
  .ql-align-justify {
    text-align: justify;
  }

  strong {
    @apply font-bold;
  }

  a {
    text-decoration: underline;
  }

  p:not(:last-child) {
    margin-bottom: 1rem;
  }

  h1 {
    @apply font-normal text-3xl leading-normal pb-4;
  }
  h2 {
    @apply font-normal text-2xl leading-normal pb-4;
  }
  // h3 {
  //   @apply font-normal text-xl leading-normal pb-4;
  // }
}

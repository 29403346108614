@import '../../styles/variables.scss';

.post-list {
  .image-thumbnail {
    height: 100%;
    > * {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}

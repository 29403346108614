@import '../../styles/variables.scss';

.hero-slider {
  height: calc(60vh);

  @media all and (min-width: 1280px) {
    height: calc(100vh - 20rem);
  }

  .overlay {
    background-color: rgba($heroOverlay, 0.52);
  }
}

@import '../../../styles/variables.scss';

.news-social {
  .overlay {
    background-color: rgba($blue, 0.92);
  }

  .share-icon {
    svg {
      rect {
        visibility: hidden;
      }
      path {
        fill: $lightBlue;
      }
    }
  }
}

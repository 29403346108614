/* Effects */
.section {
  &[class*='effect-bg-'] {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  &.effect-bg-lightblue {
    background-color: $blue;
    color: $white;
  }

  &.effect-bg-gray {
    @apply bg-gray-50;
    color: #163648;
  }

  &.effect-bg-dark-gray {
    color: white;
    background-color: #707070;
  }
}
